import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import { useGetClientScopeQuery } from '@/store/presentationApi';
import { useAppDispatch, useAppSelector } from '@/store/store';
import {
  selectAllPermissions,
  useGetPermissionsQuery,
  useLazyGetPermissionsForUserQuery,
} from '@/store/permissionsSlice';
import { selectConfig, selectNavigateAsUser, setClientScope } from '@/store/userSlice';
import { Sgb4Spinner } from '../common/Sgb4Spinner';
import { Header } from '../common/Header';
import { HelpCenter } from '../sgwt/HelpCenter';
import { MiniFooter } from '../sgwt/MiniFooter';
import { PageTracker } from '../common/PageTracker';
import { ResetScrollHandler } from '../common/ResetScrollHandler';
import { IntlProvider } from '../../context/IntlContext';
import './App.scss';
import { CollapseProvider } from '../common/CollapseProvider';
import { ToasterProvider } from '../common/ToasterProvider';
import { ConfirmationModalProvider } from '../common/ConfirmationModalProvider';
import { SplashScreen } from '../common/SplashScreen';
import { LoadingRenderComponent } from '../common/LoadingRenderComponent';

const HomeLazy = lazy(() =>
  import('../routes/Home/Home').then(({ Home }) => ({
    default: Home,
  })),
);
const TradeCaptureRouterLazy = lazy(() =>
  import('../routes/TradeCapture/TradeCaptureRouter').then(({ TradeCaptureRouter }) => ({
    default: TradeCaptureRouter,
  })),
);
const PageNotFoundLazy = lazy(() =>
  import('../common/PageNotFound').then(({ PageNotFound }) => ({
    default: PageNotFound,
  })),
);

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const clientScope = useGetClientScopeQuery();
  const { isSuccess: permissionsSuccess, isFetching: permissionsLoading } =
    useGetPermissionsQuery();
  const allPermissions = useAppSelector(selectAllPermissions);
  const navigateAsUser = useAppSelector(selectNavigateAsUser);
  const [trigger] = useLazyGetPermissionsForUserQuery();

  const matomo = createInstance({
    urlBase: config?.matomo?.url ?? '',
    siteId: config?.matomo?.siteId ?? -1,
    trackerUrl: `${config?.matomo?.url}piwik.php`,
    srcUrl: `${config?.matomo?.url}piwik.js`,
  });

  const redirectToSgwtUnauthorized = () => {
    window.location.href = `localhost:/3000?redirectUri=${window.location.href}`;
  };

  useEffect(() => {
    if (navigateAsUser) {
      trigger(navigateAsUser.name);
    }
  }, [navigateAsUser]);

  // TODO : remove this once we can use clientScope from presentationApi everywhere
  useEffect(() => {
    if (clientScope.isSuccess && !clientScope.isFetching) {
      dispatch(setClientScope(clientScope.data));
    }
  }, [clientScope]);

  useEffect(() => {
    if (
      permissionsSuccess &&
      clientScope.isSuccess &&
      (clientScope.data === '' || allPermissions === null || allPermissions.length === 0)
    ) {
      redirectToSgwtUnauthorized();
    }
  }, [permissionsSuccess]);

  return (
    <React.StrictMode>
      <IntlProvider>
        <ToasterProvider>
          <ConfirmationModalProvider>
            <MatomoProvider value={matomo}>
              <CollapseProvider>
                <BrowserRouter basename="/">
                  <PageTracker>
                    <LoadingRenderComponent isLoading={permissionsLoading}>
                      <Header />
                      <div className="flex-grow-1">
                        <Suspense
                          fallback={
                            <div className="m-5 d-flex justify-content-center">
                              <Sgb4Spinner />
                            </div>
                          }
                        >
                          <Routes>
                            <Route path="/" element={<HomeLazy />} />
                            <Route path="trade-capture/*" element={<TradeCaptureRouterLazy />} />
                            <Route element={<PageNotFoundLazy />} />
                          </Routes>
                        </Suspense>
                      </div>
                      <ResetScrollHandler />
                      <HelpCenter />
                      <MiniFooter />
                      <SplashScreen />
                    </LoadingRenderComponent>
                  </PageTracker>
                </BrowserRouter>
              </CollapseProvider>
            </MatomoProvider>
          </ConfirmationModalProvider>
        </ToasterProvider>
      </IntlProvider>
    </React.StrictMode>
  );
};

export default App;
