import {
  createEntityAdapter,
  createSelector,
  EntityState,
} from '@reduxjs/toolkit';
import { XOnePermission, XOnePermissions } from 'types/permissions';
import { presentationApi } from './presentationApi';
import { AppState } from './store';

const permissionsAdapter = createEntityAdapter<XOnePermission>({
  selectId: (permission: XOnePermission) => permission.name,
});

const permissionsInitialState = permissionsAdapter.getInitialState();

const navigateAsPermissionsAdapter = createEntityAdapter<XOnePermission>({
  selectId: (permission: XOnePermission) => permission.name,
});

const navigateAsPermissionsInitialState =
  navigateAsPermissionsAdapter.getInitialState();

export const permissionsApiSlice = presentationApi.injectEndpoints({
  endpoints: (builder) => ({
    getPermissions: builder.query<EntityState<XOnePermission>, void>({
      query: () => ({
        url: 'permissions',
        method: 'POST',
        body: {
          accessNames: ['Commands'],
        },
      }),
      transformResponse(baseQueryReturnValue: XOnePermissions, _meta, _arg) {
        return permissionsAdapter.upsertMany(
          permissionsInitialState,
          baseQueryReturnValue.permissions,
        );
      },
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getPermission: builder.query<EntityState<XOnePermission>, string>({
      query: (name) => ({
        url: `permission/${name}`,
        method: 'GET',
      }),
      transformResponse(baseQueryReturnValue: XOnePermission, _meta, _arg) {
        return permissionsAdapter.upsertOne(
          permissionsInitialState,
          baseQueryReturnValue,
        );
      },
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getPermissionsForUser: builder.query<EntityState<XOnePermission>, string>({
      query: (userName: string) => ({
        url: `permissions/users/${encodeURIComponent(userName)}`,
        method: 'POST',
        body: {
          accessNames: ['StaticData', 'Commands', 'CanManagePrivateBonds'],
        },
      }),
      transformResponse(baseQueryReturnValue: XOnePermissions, _meta, _arg) {
        return navigateAsPermissionsAdapter.setMany(
          navigateAsPermissionsInitialState,
          baseQueryReturnValue.permissions,
        );
      },
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
  }),
});

export const {
  useGetPermissionsQuery,
  useGetPermissionQuery,
  useLazyGetPermissionsForUserQuery,
} = permissionsApiSlice;

const selectPermissionsResult =
  permissionsApiSlice.endpoints.getPermissions.select();

const selectPermissionsData = createSelector(
  selectPermissionsResult,
  (permissionsResult) => permissionsResult.data,
);

export const {
  selectAll: selectAllPermissions,
  selectById: selectPermissionByName,
} = permissionsAdapter.getSelectors(
  (state: AppState) => selectPermissionsData(state) ?? permissionsInitialState,
);

const selectNavigateAsPermissionsData = createSelector(
  (state: AppState) =>
    permissionsApiSlice.endpoints.getPermissionsForUser.select(
      state.user.navigateAsUser?.name ?? '',
    )(state),
  (result) => result.data,
);

export const { selectById: selectNavigateAsPermission } =
  navigateAsPermissionsAdapter.getSelectors(
    (state: AppState) =>
      selectNavigateAsPermissionsData(state) ??
      navigateAsPermissionsInitialState,
  );

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const selectCanWritePermission = createSelector(
  [
    (state: AppState, permissionName: string) =>
      state.user.navigateAsUser
        ? selectNavigateAsPermission(state, permissionName)
        : selectPermissionByName(state, permissionName),
    (_state: AppState, _permissionName: string, accessName: string) =>
      accessName,
  ],
  (permission, accessName) =>
    permission &&
    (permission.canWrite.includes(accessName) ||
      (permission.canWrite.includes('ANY') &&
        !permission.cannotWrite.includes(accessName))),
);

const selectCanReadPermission = createSelector(
  [
    (state: AppState, permissionName: string) =>
      state.user.navigateAsUser
        ? selectNavigateAsPermission(state, permissionName)
        : selectPermissionByName(state, permissionName),
    (_state: AppState, _permissionName: string, accessName: string) =>
      accessName,
  ],
  (permission, accessName) =>
    permission &&
    (permission.canRead.includes(accessName) ||
      (permission.canRead.includes('ANY') &&
        !permission.cannotRead.includes(accessName))),
);

export const selectCanViewScreen = createSelector(
  [
    (state: AppState) => state,
    (_state: AppState, screenName: string) => screenName,
  ],
  (state: AppState, screenName: string) =>
    selectCanReadPermission(state, 'Commands', screenName),
);
