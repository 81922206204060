import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '@/config/config';
import { commonPrepareHeaders } from './apiUtils';

export const documentationApi = createApi({
  reducerPath: 'documentationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}documentation/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  endpoints: (builder) => ({
    getDocumentationForSession: builder.query<DocumentationResult, string>({
      query: (sessionId) => ({
        url: `trade-sessions/${sessionId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetDocumentationForSessionQuery } = documentationApi;
