import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ShortPartyModel } from '@/types/PartyCRM.model';
import { getConfig } from '../config/config';
import { commonPrepareHeaders } from './apiUtils';

export const staticDataApi = createApi({
  reducerPath: 'staticDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}static-data/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  endpoints: (builder) => ({
    // Parties
    searchParties: builder.query<ShortPartyModel[], string>({
      query: (like) => ({
        url: `parties/${like}`,
        method: 'GET',
      }),
      transformResponse: (baseQueryReturnValue: { parties: ShortPartyModel[] }, _meta, _arg) =>
        baseQueryReturnValue.parties,
      // Keep result for 10 minutes, cache value will only be used when using the "back" button from detail
      keepUnusedDataFor: 60 * 10,
    }),
  }),
});

export const { useLazySearchPartiesQuery } = staticDataApi;
