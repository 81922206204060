import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface LocationProps {
  shouldResetScroll: boolean;
}

export const ResetScrollHandler: React.FC = () => {
  const { pathname, state, hash } = useLocation();

  const { shouldResetScroll = false } = (state as LocationProps) ?? {};

  const scrollToElement = () => {
    const elem = document.getElementById(hash.replace('#', ''));
    if (elem) {
      elem.scrollIntoView({ behavior: 'auto' });
      return true;
    }
    return false;
  };

  const pageObserverCallback: MutationCallback = (
    mutations: MutationRecord[],
    observer: MutationObserver,
  ) => {
    if (scrollToElement()) {
      observer.takeRecords();
      observer.disconnect();
    }
  };

  useLayoutEffect(() => {
    if (shouldResetScroll) {
      window.scrollTo(0, 0);
    }
    if (hash !== '') {
      if (scrollToElement()) {
        return undefined;
      }
      const pageObserver = new MutationObserver(pageObserverCallback);
      pageObserver.observe(document, {
        attributes: false,
        childList: true,
        subtree: true,
      });
      const timeoutObserverId = setTimeout(() => {
        pageObserver.disconnect();
      }, 2000);
      return () => {
        clearTimeout(timeoutObserverId);
        pageObserver.disconnect();
      };
    }
    return undefined;
  }, [pathname, hash]);

  return null;
};
