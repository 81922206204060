import { combineReducers, configureStore, PreloadedState, SerializedError } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import userReducer from './userSlice';
import { presentationApi } from './presentationApi';
import { bookingApi } from './bookingApi';
import { tradeCaptureApi } from './tradeCaptureApi';
import { documentationApi } from './documentationApi';
import { staticDataApi } from './staticDataApi';
import { pdcHubApi } from './pdcHubApi';

const appReducer = combineReducers({
  user: userReducer,
  [presentationApi.reducerPath]: presentationApi.reducer,
  [bookingApi.reducerPath]: bookingApi.reducer,
  [tradeCaptureApi.reducerPath]: tradeCaptureApi.reducer,
  [documentationApi.reducerPath]: documentationApi.reducer,
  [staticDataApi.reducerPath]: staticDataApi.reducer,
  [pdcHubApi.reducerPath]: pdcHubApi.reducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setupStore = (preloadedState?: PreloadedState<AppState>) =>
  configureStore({
    reducer: appReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['user/setSgConnect'],
          ignoredPaths: ['user.globalContext.setClientScope', 'user.globalContext.sgConnect'],
        },
      })
        .concat(presentationApi.middleware)
        .concat(bookingApi.middleware)
        .concat(tradeCaptureApi.middleware)
        .concat(documentationApi.middleware)
        .concat(staticDataApi.middleware)
        .concat(pdcHubApi.middleware),
  });

export function getErrorMessage(error: FetchBaseQueryError | SerializedError): string {
  let errMsg: string;
  if ('status' in error) {
    if (error.data) return JSON.stringify(error.data);
    errMsg = 'error' in error ? error.error : JSON.stringify(error.data);
  } else {
    errMsg = error.message ?? 'Unexpected error';
  }
  return errMsg;
}

export type AppState = ReturnType<typeof appReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const store = setupStore();
