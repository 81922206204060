import { useEffect, useContext } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useLocation } from 'react-router-dom';
// import { useGetUserInfoQuery } from '@/state/api/web.api';
import { useIntl } from 'react-intl';

import { addUserConsentSettingsCallback, getUserConsentOnMatomo } from '@/helpers/cookieConsent';
import { UserContext } from '@/context/UserContext';


export function PageTracker({ children }: { children: JSX.Element }): JSX.Element {
  const { trackPageView, pushInstruction } = useMatomo();
  const globalSettings = useContext(UserContext)
  const location = useLocation();
//  const userInfo = useGetUserInfoQuery();
  const { locale } = useIntl();

  const sgConnect = globalSettings.globalContext.sgConnect

  useEffect(() => {
    pushInstruction('setUserId', sgConnect?.getIdTokenClaims()?.sub);
    pushInstruction('setCustomVariable', 1, 'locale', locale, 'visit');
    trackPageView({});
  }, [pushInstruction, locale, trackPageView]);

  // Every time the location changes, we track the page view on Matomo.
  useEffect(() => {
    function updateConsent() {
      getUserConsentOnMatomo().then(consent => {
        // Documentation: https://developer.sgmarkets.com/docs/web/widgets/structural-widgets/advanced-usage/cookies-consent.html
        if (consent) {
          pushInstruction('rememberCookieConsentGiven', 4320);
          pushInstruction('setCustomDimension', 10, true);
        } else {
          pushInstruction('forgetCookieConsentGiven');
          pushInstruction('setCustomDimension', 10, false);
        }
      });
    }

    pushInstruction('requireCookieConsent');
    updateConsent();
    trackPageView({});
    addUserConsentSettingsCallback(updateConsent);

    // Only care about change location
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <>{children}</>;
}