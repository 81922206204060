import React from 'react';
import type { GlobalContext } from '@sgmo/shared';

export interface UserContextProps {
  globalContext: GlobalContext;
}

export const UserContext = React.createContext<UserContextProps>({
  globalContext: {
    appConfig: null,
    clientScopeCode: '',
    permissions: null,
    setClientScope: () => undefined,
    sgConnect: null,
  },
});
