import React, { PropsWithChildren, ReactNode } from 'react';
import { Sgb4Spinner } from './Sgb4Spinner';

interface LoadingRenderComponentProps {
  loadingComponent?: ReactNode;
  isLoading: boolean;
}

export const LoadingRenderComponent: React.FC<
  PropsWithChildren<LoadingRenderComponentProps>
> = ({
  loadingComponent,
  isLoading,
  children,
}: PropsWithChildren<LoadingRenderComponentProps>) => {
  if (isLoading && loadingComponent) {
    return <>{loadingComponent}</>;
  }
  if (isLoading) {
    return (
      <div className="mt-5 d-flex justify-content-center">
        <Sgb4Spinner />
      </div>
    );
  }
  return <>{children}</>;
};
