import { BaseQueryApi } from '@reduxjs/toolkit/query';
import { UserState } from './userSlice';

export function commonPrepareHeaders(
  headers: Headers,
  { getState }: Pick<BaseQueryApi, 'getState' | 'type' | 'extra' | 'endpoint' | 'forced'>,
): Headers {
  const { user } = getState() as { user: UserState };
  const authHeader = user.globalContext.sgConnect?.getAuthorizationHeader();

  headers.set('Authorization', authHeader as string);

  if (!headers.has('no-client-scope')) {
    const clientScope = user.globalContext.clientScopeCode;
    headers.set('X-Client-Profile', clientScope);
  } else {
    // Do not keep this fake header
    headers.delete('no-client-scope');
  }
  return headers;
}
