import React, { useState } from 'react';
import { CollapseContext } from '../../context/CollapseContext';

export const CollapseProvider: React.FC = ({
  children,
}: OnlyChildrenProps) => {
  const [isCollapsed, setCollapsed] = useState<boolean>(false);

  return (
    <CollapseContext.Provider value={{ isCollapsed, setCollapsed }}>
      {children}
    </CollapseContext.Provider>
  );
};
