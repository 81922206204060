import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ConfirmationModalContext } from '../../context/ConfirmationModalContext';

export const ConfirmationModalProvider: React.FC = ({ children }: OnlyChildrenProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [
    formattedMessage,
    setFormattedMessage,
  ] = useState<FormattedMessageProps>({ id: 'label.ask-for-confirmation' });
  const [callback, setCallback] = useState<() => void>(() => undefined);

  const askForConfirmation = (
    intlMessageId: FormattedMessageProps,
    onConfirm: () => void,
  ) => {
    setCallback(() => onConfirm);
    setFormattedMessage(intlMessageId);
    setOpen(true);
  };
  const resetModal = () => {
    setOpen(false);
    setCallback(() => () => undefined);
    setFormattedMessage({ id: 'label.ask-for-confirmation' });
  };
  const executeOnConfirm = () => {
    callback();
    resetModal();
  };

  return (
    <ConfirmationModalContext.Provider value={{ askForConfirmation }}>
      {children}
      <Modal isOpen={isOpen} className="modal-dialog-centered">
        <ModalHeader>
          <FormattedMessage id="label.ask-for-confirmation" />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage
            id={formattedMessage.id}
            values={formattedMessage.values}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-lg btn-flat-secondary"
            onClick={resetModal}
          >
            <FormattedMessage id="label.cancel" />
          </button>
          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={executeOnConfirm}
          >
            <FormattedMessage id="label.confirm" />
          </button>
        </ModalFooter>
      </Modal>
    </ConfirmationModalContext.Provider>
  );
};
