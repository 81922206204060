import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationConfiguration, GlobalContext } from '@sgmo/shared';
import { NavigateAsUser } from '@sgmo/shared-components';
import { SGWTConnectCore } from '@sgwt/connect-core';

export interface UserState {
  globalContext: GlobalContext;
  loadingPermissions: boolean;
  navigateAsUser?: NavigateAsUser;
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    globalContext: {
      clientScopeCode: '',
      setClientScope: () => undefined,
      permissions: null,
      appConfig: null,
      sgConnect: null,
    },
    loadingPermissions: true,
    navigateAsUser: undefined,
  } as UserState,
  reducers: {
    setClientScope(state, action: PayloadAction<string>) {
      state.globalContext.clientScopeCode = action.payload;
    },
    setSgConnect(state, action: PayloadAction<SGWTConnectCore>) {
      state.globalContext.sgConnect = action.payload;
    },
    setAppConfig(state, action: PayloadAction<ApplicationConfiguration>) {
      state.globalContext.appConfig = action.payload;
    },
    setNavigateAsUser(
      state,
      action: PayloadAction<NavigateAsUser | undefined>,
    ) {
      if (action.payload?.name === state.navigateAsUser?.name) {
        return;
      }
      if (action.payload === undefined) {
        console.info('Stopping navigating as', state.navigateAsUser?.name);
      } else {
        console.info('Starting navigating as', action.payload.name);
      }
      state.navigateAsUser = action.payload;
    },
  },
});

export const { setClientScope, setSgConnect, setAppConfig, setNavigateAsUser } =
  userSlice.actions;

export default userSlice.reducer;

export const selectContext = (state: { user: UserState }): GlobalContext =>
  state.user.globalContext;

export const selectConfig = (state: {
  user: UserState;
}): ApplicationConfiguration =>
  state.user.globalContext.appConfig ?? ({} as ApplicationConfiguration);

export const selectLoadingPermissions = (state: { user: UserState }): boolean =>
  state.user.loadingPermissions;

export const selectNavigateAsUser = (state: {
  user: UserState;
}): NavigateAsUser | undefined => state.user.navigateAsUser;
