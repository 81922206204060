import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PdcHubApiResponse } from  '@sgmo/shared-components';
import { getConfig } from '@/config/config';
import { commonPrepareHeaders } from './apiUtils';

export const pdcHubApi = createApi({
  reducerPath: 'pdchubapi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}pdchub/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  tagTypes: ['PdcHub'],
  endpoints: (builder) => ({
    checkdeliveryrisk: builder.mutation<
    PdcHubApiResponse,
      { sessionId: string; version: number; actionType: string; workflowType: string}
    >({
      query: ({ sessionId, version, actionType, workflowType}) => ({
        url: `trade-sessions/${sessionId}/versions/${version}`,
        method: 'POST',
        params: [
          ['actionType', actionType],
          ['workflowType', workflowType],
        ]
      })
  }),
}),
});

export const {
  useCheckdeliveryriskMutation
} = pdcHubApi;
