import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../config/config';
import { commonPrepareHeaders } from './apiUtils';

export const presentationApi = createApi({
  reducerPath: 'presentationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}presentation/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  tagTypes: ['ClientScope', 'Permissions'],
  endpoints: (builder) => ({
    getClientScopes: builder.query<XOneClientScope[], void>({
      query: () => ({
        url: 'client-scopes',
        method: 'GET',
        headers: [['no-client-scope', 'true']],
      }),
      transformResponse: (baseQueryReturnValue: { clientScopes: XOneClientScope[] }, _meta, _arg) =>
        baseQueryReturnValue.clientScopes,
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    getClientScope: builder.query<string, void>({
      query: () => ({
        url: 'preferences/client-scope',
        method: 'GET',
        headers: [['no-client-scope', 'true']],
      }),
      transformResponse: (
        baseQueryReturnValue: { code: string },
        _meta,
        _arg,
      ) => baseQueryReturnValue.code,
      providesTags: ['ClientScope'],
      keepUnusedDataFor: 60 * 60 * 24 * 365,
    }),
    setClientScope: builder.mutation<void, string>({
      query: (newClientScopeCode) => ({
        url: 'preferences/client-scope',
        method: 'PUT',
        params: [['clientScope', newClientScopeCode]],
        headers: [['no-client-scope', 'true']],
      }),
      invalidatesTags: ['ClientScope'],
    }),
  }),
});

export const { useGetClientScopesQuery, useGetClientScopeQuery, useSetClientScopeMutation } =
  presentationApi;
