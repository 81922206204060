import React from 'react';

interface CollapseProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

export const CollapseContext = React.createContext<CollapseProps>({
  isCollapsed: window.innerWidth < 1800,
  setCollapsed: () => {
    throw new Error('No CollapseContext.Provider defined');
  },
});
