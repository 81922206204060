import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../config/config';
import { commonPrepareHeaders } from './apiUtils';
import { JsonObj } from '../components/dynamic-data-schema/DataSchema';

export const tradeCaptureApi = createApi({
  reducerPath: 'tradeCaptureApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig()?.api?.endpoint ?? ''}trade-capture/`,
    prepareHeaders: commonPrepareHeaders,
  }),
  endpoints: (builder) => ({
    createSession: builder.mutation<
      { Version: number; Data: JsonObj },
      { sessionId: string; dealBody: JsonObj }
    >({
      query: ({ sessionId, dealBody }) => ({
        url: `sessions/${sessionId}`,
        method: 'PUT',
        body: dealBody,
      }),
    }),
    createExistingDealSession: builder.mutation<
      { Version: number; Data: JsonObj; Etag: string | null | undefined },
      { sessionId: string; dealId: string }
    >({
      query: ({ sessionId, dealId }) => ({
        url: `sessions/${sessionId}/trades/${dealId}`,
        method: 'PUT',
      }),
      transformResponse(baseQueryReturnValue: { Version: number; Data: JsonObj }, meta, _args) {
        if (meta && meta.response && meta.response.headers.has('etag')) {
          const etag = meta.response.headers.get('etag');
          return { ...baseQueryReturnValue, Etag: etag };
        }

        return { ...baseQueryReturnValue, Etag: undefined };
      },
    }),
    patchSession: builder.mutation<
      { Version: number; Data: JsonObj },
      { sessionId: string; version: number; productInput: JsonObj }
    >({
      query: ({ sessionId, version, productInput }) => ({
        url: `sessions/${sessionId}`,
        method: 'PATCH',
        body: { Version: version, Data: productInput },
      }),
    }),
  }),
});

export const {
  useCreateSessionMutation,
  useCreateExistingDealSessionMutation,
  usePatchSessionMutation,
} = tradeCaptureApi;
