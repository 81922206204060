export interface SgFooterWidget extends Element {
	getUserConsentOnMatomo(): Promise<boolean>;
}

export function getFooterWidget(): SgFooterWidget | null {
	return document.querySelector('sgwt-mini-footer');
}

export function addUserConsentSettingsCallback(callback: () => void) {
	const widget = getFooterWidget();
	if (!widget) {
		return;
	}
	widget.addEventListener('sgwt-mini-footer--current-user-consent', callback);
	widget.addEventListener('sgwt-mini-footer--user-consent-changed', callback);
}

export function removeUserConsentSettingsCallback(callback: () => void) {
	const widget = getFooterWidget();
	if (!widget) {
		return;
	}
	widget.removeEventListener('sgwt-mini-footer--current-user-consent', callback);
	widget.removeEventListener('sgwt-mini-footer--user-consent-changed', callback);
}

export function getUserConsentOnMatomo() {
	const widget = getFooterWidget();
	if (widget && widget.getUserConsentOnMatomo) {
		return widget.getUserConsentOnMatomo();
	}
	return Promise.resolve(false);
}