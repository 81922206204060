import React from 'react';
import { createRoot } from 'react-dom/client';
import { setupSgwtConnectWidget } from '@sgmo/shared';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import App from './components/App/App';
import { getConfig } from './config/config';
import { store } from './store/store';
import { setAppConfig, setSgConnect } from './store/userSlice';
import { permissionsApiSlice } from './store/permissionsSlice';

const config = getConfig();
const sgwtConnect = setupSgwtConnectWidget(getConfig());

if (sgwtConnect.isAuthorized()) {
  console.info('SgConnect is authorized, rendering App...');

  store.dispatch(setAppConfig(config));
  store.dispatch(setSgConnect(sgwtConnect));
  store.dispatch(permissionsApiSlice.endpoints.getPermissions.initiate());

  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);
  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
} else if (sgwtConnect.getAuthorizationError()) {
  console.error('SgConnect has authorization error');
  document.body.innerHTML = `
    <div class="alert alert-danger" role="alert">F
      Authorization error: ${sgwtConnect.getAuthorizationError()}.
    </div>`;
} else {
  console.info('Requesting authorization to SgConnect...');
  sgwtConnect.requestAuthorization();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
